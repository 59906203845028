import { Component } from '@angular/core';
import { faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faUser, faBuilding, faChartLine, faLaptop } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {
  name = 'psicologa-jaqueline-silva';
  panelOpenState = false;
  faLinkedin = faLinkedin;
  faEnvelope = faEnvelope;
  faWhatsapp = faWhatsapp;
  faPerson = faUser;
  faBuilding = faBuilding;
  faChartLine = faChartLine;
  faLaptop = faLaptop;

}
