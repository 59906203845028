import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AppComponent } from './app.component';
import { MessageComponent } from './message/message.component';
import { MainComponent } from './main/main.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: 'message',
    component: MessageComponent,
  },
  {
    path: 'home',
    component: MainComponent,
  },
  {
    path: '',
    redirectTo: 'home', // Empty path will redirect to content route.
    pathMatch: 'full'
  }
 ];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
