<div class="page">
    <!-- <div class="menu">
      <a [routerLink]='"."' fragment="about">Sobre</a>
      <a [routerLink]='"."' fragment="services">Serviços</a>
      <a [routerLink]='"."' fragment="events">Eventos</a>
      <a [routerLink]='"."' fragment="contact">Contato</a>
    </div> -->
    <div class="logo">
      <img src="assets/logo.svg" alt="image">
    </div>
  
    <div class="content">
      <section class="about">
        <div class="content" id="about">
          <div class="content-text">
            <h3>Sobre</h3>
            <p>
              Psicóloga formada em 2009 pela Universidade Paulista, com especialização em Psicopedagogia Construtiva
              pela Unicamp e Gestão Estratégica de Pessoas pela FAJ. Em formação com Mentoria para Intervenção aos
              Sofrimentos Extremos.
              Headhunter e Especialista na área de Recrutamento e Seleção e Psicóloga Clínica com atendimento a partir dos
              3 anos de idade.
            </p>
          </div>
          <div class="content-image">
            <img src="assets/foto_sobre.jpg">
          </div>
        </div>
      </section>
  
      <section class="service">
        <div class="content" id="services">
          <div class="services">
            <div class="division">
              <span class="title">
                <span class="icon">
                  <fa-icon [icon]="faPerson"></fa-icon>
                </span>
                <p>Atendimento pessoal</p>
              </span>
              <ul>
                <li>Atendimento online</li>
                <li>Atendimento presencial particular ou conveniados (Clínica em Jaguariúna e Campinas)</li>
              </ul>
            </div>
            <div class="division">
              <span class="title">
                <span class="icon">
                  <fa-icon [icon]="faBuilding"></fa-icon>
                </span>
                <p>Serviços para empresas</p>
              </span>
              <ul>
                <li>Especialista em processos de Recrutamento e Seleção</li>
                <li>Avaliação Psicológica (Atenção Concentrada, Palográfico, Quati, Habilitada Humanguide)</li>
                <li>Emprego Apoiado (Acompanhamento de PCD)</li>
                <li>Suicídio (Trabalhos de Prevenção e Posvenção sobre o Tema)</li>
              </ul>
            </div>
            <div class="division">
              <span class="title">
                <span class="icon">
                  <fa-icon [icon]="faChartLine"></fa-icon>
                </span>
                <p>Orientação profissional</p>
              </span>
              <ul>
                <li>Atendimentos para Orientação Profissional (Recolocação Profissional, Direcionamento de Carreira e
                  Aposentadoria)</li>
                <li>Orientação Vocacional (Individual e em Grupos)</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section class="events">
        <div class="content" id="events">
          <fieldset>
            <legend>Palestras e Eventos</legend>
            <app-carousel></app-carousel>
          </fieldset>
        </div>
      </section>
      <section class="contact">
        <div class="content" id="contact">
          <span class="text">Entre em contato para saber mais sobre meu trabalho
            <div class="links">
              <a class="linkedin" href="https://www.linkedin.com/company/34605027">
                <fa-icon [icon]="faLinkedin"></fa-icon>
              </a>
              <a class="google" href="mailto:jaquelinesilva.psi@gmail.com">
                <fa-icon [icon]="faEnvelope"></fa-icon>
              </a>
              <a class="whatsapp" href="https://wa.me/5519991405797">
                <fa-icon [icon]="faWhatsapp"></fa-icon>
              </a>
            </div>
          </span>
          <div class="contacts">
            <p>Email: jaquelinesilva.psi@gmail.com</p>
            <p>Telefone: (19) 99140-5797</p>
          </div>
        </div>
      </section>
    </div>
    <div class="footer">
      <p>Desenvolvido por <a href="https://www.linkedin.com/in/ronerjr/">Roner Dâmaso Junior</a></p>
    </div>
  </div>