<div class="body">
    <div class="message">
        <blockquote>
            "Que o teu despertar te desperte. <br>
            E que quando acordas, o dia que começa te excite. <br>
            E que os raios de sol que se filtram pela janela a cada novo amanhecer nunca se tornem rotina. <br>
            E que tenhas a lucidez para concentrar e resgatar o que há de mais positivo em cada pessoa que cruza o teu
            caminho. <br>
            E não te esqueças de saborear a comida, mesmo que seja apenas pão e água. <br>
            E encontrar algum momento do dia, mesmo que curto e breve, para erguer o olhar e agradecer o milagre da
            saúde,
            esse mistério e fantástico equilíbrio interno. <br>
            E que consiga expressar o amor que sente aos outros. <br>
            E que os teus abraços, abracem. <br>
            E que os teus beijos, beijem. <br>
            E que o pôr do sol nunca deixe de te surpreender, e que nunca deixes de te surpreender contigo. <br>
            E que chegues cansado e satisfeito ao entardecer pelas tarefas realizadas durante o dia. <br>
            E que o teu sono seja calmo, restaurador e suave. <br>
            E não confundas o trabalho com a vida, ou o valor das coisas com o seu preço. <br>
            E não acredites que és mais do que alguém, porque só os ignorantes não sabem que não somos nada além de pó e
            cinzas. <br>
            E não te esqueças, nem por um momento, que cada segunda de vida é uma dádiva, uma benção, e que se fôssemos
            realmente corajosos, dançaríamos e cantaríamos de alegria quando tivéssemos consciência disso como uma
            pequena
            homenagem ao mistério da vida que nos envolve e nos abençoa." <br> (marinarepettooficial)
        </blockquote>
        <div class="footer">
            <img src="assets/jaquelineb.jpg" alt="Foto Jaqueline">
            <div class="wishes">
                <p>Desejo a você um Feliz Natal e um 2022 iluminado!</p>
                <p class="signature">Jaqueline Silva</p>
            </div>
        </div>
    </div>
</div>