import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  images = [];

  constructor() { }

  ngOnInit(): void {
    console.log("init");
    this.images = [{
      image: '../../assets/evento_1.jpg',
      text: 'Janeiro Branco - Saúde Mental'
    },
    {
      image: '../../assets/evento_2.jpg',
      text: 'Janeiro Branco - Saúde Mental'
    },
    {
      image: '../../assets/evento_3.jpg',
      text: 'Janeiro Branco - Saúde Mental'
    },
    {
      image: '../../assets/evento_4.jpg',
      text: 'Setembro Amarelo - Palestra sobre suícido'
    },
    {
      image: '../../assets/evento_5.jpg',
      text: 'Treinamento para Atendimento ao Cliente'
    },
    {
      image: '../../assets/evento_6.jpg',
      text: 'Treinamento para Atendimento ao Cliente'
    },
    {
      image: '../../assets/evento_7.jpg',
      text: 'Dia das Mulheres - empoderamento e compaixão'
    },
    {
      image: '../../assets/evento_8.jpg',
      text: ''
    }];
  }

}
